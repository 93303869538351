import { Link, useHistory } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { MerchantPane } from './MerchantPane'
import { DepositResult } from '../DepositResult'
import { CancelContainer, FooterContainer, PaymentContainer } from './Checkout.styled'
import { Button, ErrorDisplay, Input, Preloader } from 'mmfintech-portal-commons'
import { CheckoutContainer, CheckoutContent, LabelValue, SelectMethod } from '../../components'

import {
  formatMoney,
  isEmptyString,
  isValidArray,
  isValidObject,
  isValidString,
  tr,
  translateCountryName
} from 'mmfintech-commons'
import {
  actions,
  CheckoutFlowStep,
  fakeJetonBankBankList,
  isJetonWallet,
  useCheckout,
  useUnloadBeacon
} from 'mmfintech-checkout-commons'

import { ThunkDispatch } from 'redux-thunk'
import {
  CheckoutMethodField,
  CountryChoice,
  PaymentMethodEnum,
  PaymentOption,
  PaymentSessionStatusEnum,
  SupportedBank
} from 'mmfintech-commons-types'
import { useEffect, useState } from 'react'
import { useFakeBankLogin } from './useFakeBankLogin'

const Checkout = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const sessionId = searchParams.get('sessionId')

  const {
    currencies,
    logo,
    banks,
    banksCount,
    checkoutOptions,
    checkoutCountries,
    checkoutCountriesError,
    checkoutError,
    checkoutPay,
    checkoutPayFetching,
    session,
    sessionFetching
  } = useSelector(
    ({
      common: { currencies },
      checkout: {
        logo,
        banks,
        banksCount,
        checkoutOptions,
        checkoutCountries,
        checkoutCountriesError,
        checkoutError,
        checkoutPay,
        checkoutPayFetching,
        session,
        sessionFetching
      }
    }) => ({
      currencies,
      logo,
      banks,
      banksCount,
      checkoutOptions,
      checkoutCountries,
      checkoutCountriesError,
      checkoutError,
      checkoutPay,
      checkoutPayFetching,
      session,
      sessionFetching
    }),
    shallowEqual
  )

  const [triggered, setTriggered] = useState(false)

  const history = useHistory()
  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()

  const { sessionStatus, paymentResult, paymentResultType, processingAmount, processingCurrency, showCancelButton } =
    session || {}
  useUnloadBeacon({ sessionId })

  const {
    step,
    setStep,
    fields,
    status,
    logEvent,
    handlePay,
    formValues,
    countryCode,
    setCountryCode,
    paymentMethod,
    setPaymentMethod,
    hasBankSelection,
    handleStartOver,
    jetonBankSpecialCase,
    prepareValidations,
    initialized
  } = useCheckout({
    enableLogging: true,
    preselectSingleMethod: true,
    enableSpecialJetonBankCase: true,
    initialStep: CheckoutFlowStep.SELECT_METHOD
  })

  const [filteredFields, setFilteredFields] = useState(fields)

  const fakeLogin = useFakeBankLogin()

  const internalHandlePay = () => {
    if (jetonBankSpecialCase && !isJetonWallet(formValues.getValue('bankChoiceId'))) {
      fakeLogin.reset()
      setStep(71)
      return
    }

    handlePay()
  }

  const getFakeBankIndex = () => {
    const index = parseInt(formValues.getValue('bankChoiceId'))
    return isFinite(index) ? index - 1 : -1
  }

  const handleFakePay = () => {
    if (fakeLogin.values.areValid()) {
      void fakeLogin.login(fakeJetonBankBankList[countryCode][getFakeBankIndex()]?.url)
    }
  }

  const isCountrySelectionVisible = () =>
    Array.isArray(checkoutCountries) && checkoutCountries.length > 1 && !session?.country

  useEffect(() => {
    if (!isValidArray(fields)) {
      setFilteredFields(fields)
    }

    if (checkoutOptions && isValidArray(fields)) {
      const data = {}
      if (isJetonWallet(formValues.getValue('bankChoiceId'))) {
        const jetonFields = checkoutOptions.find((option: any) => isJetonWallet(option.paymentMethod))?.fields
        setFilteredFields(jetonFields)
        isValidArray(jetonFields) &&
          jetonFields.forEach((item: CheckoutMethodField) => {
            const { name, prefilledValue: value, required } = item
            data[name] = { value, required, ...prepareValidations(item) }
          })
        formValues.reset(data)
        formValues.setValue('bankChoiceId', PaymentMethodEnum.JETON)
      } else {
        const selectedPaymentMethodFields = checkoutOptions.find(
          (option: any) => option.paymentMethod === paymentMethod
        )?.fields
        setFilteredFields(selectedPaymentMethodFields)
        const bankChoiceId = formValues.getValue('bankChoiceId')
        isValidArray(selectedPaymentMethodFields) &&
          selectedPaymentMethodFields.forEach((item: CheckoutMethodField) => {
            if (item.name !== 'bankChoiceId') {
              const { name, prefilledValue: value, required } = item
              data[name] = { value, required, ...prepareValidations(item) }
            }
          })
        jetonBankSpecialCase && formValues.reset(data)
        jetonBankSpecialCase && formValues.setValue('bankChoiceId', bankChoiceId)
      }
    }
  }, [formValues.getValue('bankChoiceId'), checkoutOptions, fields])

  const handleCancelClick = () => {
    if (sessionId) {
      void dispatch(actions.checkout.cancelSessionPayment(sessionId))
    }
    logEvent('cancelled_by_payer')
    history.push('/fail')
    return false
  }

  const handleBankSelected = (code: string) => {
    logEvent('bank_selected', code)
  }

  const parseResult = () => {
    try {
      return JSON.parse(paymentResult)
    } catch (error) {
      return paymentResult
    }
  }

  const prepareResponse = () =>
    sessionStatus?.value === PaymentSessionStatusEnum.IN_PROGRESS
      ? { result: parseResult(), resultType: paymentResultType, processingAmount, processingCurrency }
      : checkoutPay

  const selectedPaymentOption = paymentMethod
    ? checkoutOptions.find((method: any) => method.paymentMethod === paymentMethod)
    : {}

  const currencyOptions = isValidObject(selectedPaymentOption) ? selectedPaymentOption.supportedCurrencies : []

  const prepareBankOptions = () => {
    if (jetonBankSpecialCase) {
      const result = []
      result.push({
        value: PaymentMethodEnum.JETON,
        label: tr('METADATA.PAYMENT_OPTIONS.JETON', 'Jeton')
      })

      if (Array.isArray(fakeJetonBankBankList[countryCode])) {
        fakeJetonBankBankList[countryCode].forEach((bank, index) => {
          result.push({
            value: index + 1,
            label: bank.name
          })
        })
      }

      return result
    }

    return Array.isArray(banks)
      ? banks.map((bank: SupportedBank) => {
          const { bankChoiceId, name } = bank
          return { value: bankChoiceId, label: name }
        })
      : []
  }

  const hasCountry = (countryCode: string): boolean =>
    Array.isArray(checkoutCountries) &&
    !!checkoutCountries.find((country: CountryChoice) => country.countryCode === countryCode)

  const hasOnlyOneCountry = (): boolean =>
    (session.country && hasCountry(session.country)) ||
    (Array.isArray(checkoutCountries) && checkoutCountries.length === 1)

  const validBankSelection = () => {
    if (jetonBankSpecialCase) {
      return hasOnlyOneCountry() && !fakeJetonBankBankList[countryCode]
    }

    const exists = hasBankSelection()
    return !exists || (exists && banksCount === 1 && isValidString(formValues.getValue('bankChoiceId')))
  }

  const validFields = () => {
    const option = checkoutOptions?.find((v: PaymentOption) => v.paymentMethod === paymentMethod)
    if (option) {
      const { fields } = option
      if (Array.isArray(fields)) {
        const find = fields.find((item: CheckoutMethodField) => {
          const { name, prefilledValue, displayIfPrefilled } = item
          return (!prefilledValue?.length || displayIfPrefilled) && name !== 'bankChoiceId'
        })
        return !find
      }
    }
    return true
  }

  useEffect(() => {
    if (paymentMethod && jetonBankSpecialCase) {
      formValues.setValue('bankChoiceId', PaymentMethodEnum.JETON)
    }
  }, [paymentMethod])

  useEffect(() => {
    if (
      initialized &&
      !triggered &&
      !checkoutPayFetching &&
      isValidString(paymentMethod) &&
      validBankSelection() &&
      validFields()
    ) {
      setTriggered(true)
    }
    // eslint-disable-next-line
  }, [initialized, formValues.getValue('bankChoiceId')])

  useEffect(() => {
    if (triggered) {
      handlePay()
    }
  }, [triggered])

  return (
    <CheckoutContainer>
      <CheckoutContent>
        <ErrorDisplay error={checkoutCountriesError} />

        {sessionFetching || !initialized ? (
          <PaymentContainer>
            <Preloader />
          </PaymentContainer>
        ) : (
          <>
            {!checkoutError && <MerchantPane session={session} logo={logo} />}

            {step === CheckoutFlowStep.SELECT_METHOD ||
            step === CheckoutFlowStep.SELECT_BANK ||
            step === CheckoutFlowStep.ADDITIONAL_FIELDS ? (
              <>
                <PaymentContainer>
                  {status === 'IDLE' && (
                    <>
                      {isCountrySelectionVisible() ? (
                        <div className='mb-3'>
                          <Input
                            type='select'
                            name='countryCode'
                            label={tr('CHECKOUT.PAYMENT.COUNTRY', 'Country')}
                            placeholder={tr('CHECKOUT.PAYMENT.COUNTRY_PLACEHOLDER', 'Select country')}
                            options={checkoutCountries?.map((country: CountryChoice) => ({
                              value: country.countryCode,
                              label: country.name
                            }))}
                            value={countryCode}
                            onChange={(_name: string, value: string) => setCountryCode(value)}
                            hideErrorLine
                            required
                          />
                        </div>
                      ) : (
                        <div className='mb-3'>
                          <Input
                            type='static'
                            label={tr('CHECKOUT.PAYMENT.COUNTRY', 'Country')}
                            value={translateCountryName(countryCode)}
                            hideErrorLine
                          />
                        </div>
                      )}

                      <SelectMethod
                        className='use-grid'
                        method={paymentMethod}
                        setMethod={setPaymentMethod}
                        specialCase={jetonBankSpecialCase}
                        errorVisible={!!countryCode}
                      />

                      {paymentMethod && jetonBankSpecialCase && (
                        <>
                          <div className='mt-2' />

                          <Input
                            type='select'
                            label={tr('CHECKOUT.PAYMENT.BANK', 'Bank')}
                            {...formValues.registerInput('bankChoiceId', handleBankSelected)}
                            options={prepareBankOptions()}
                            required={true}
                            hideErrorLine
                          />
                        </>
                      )}

                      {(isValidArray(filteredFields) && !jetonBankSpecialCase) ||
                      (isValidArray(filteredFields) && formValues.getValue('bankChoiceId') && jetonBankSpecialCase) ? (
                        <>
                          <div className='mt-2' />

                          {filteredFields.map((item, index) => {
                            const { name, type, defaultLabel, translationKey, required } = item

                            if (name === 'bankChoiceId') {
                              return jetonBankSpecialCase ? null : (
                                <Input // to prevent render banks filed twice
                                  key={index}
                                  type='select'
                                  label={tr('CHECKOUT.PAYMENT.BANK', 'Bank')}
                                  {...formValues.registerInput(name, handleBankSelected)}
                                  options={
                                    Array.isArray(banks)
                                      ? banks.map((bank: SupportedBank) => {
                                          const { bankChoiceId, name } = bank
                                          return { value: bankChoiceId, label: name }
                                        })
                                      : []
                                  }
                                  required={required}
                                  hideErrorLine
                                />
                              )
                            }

                            if (name === 'currency') {
                              return (
                                <Input
                                  key={index}
                                  type='select'
                                  label={tr(translationKey, defaultLabel)}
                                  {...formValues.registerInput(name)}
                                  options={currencyOptions?.map((currency: string) => {
                                    return { value: currency, label: currency }
                                  })}
                                  required={required}
                                  hideErrorLine
                                />
                              )
                            }

                            if (name === 'documentId' && paymentMethod === 'PIX') {
                              return (
                                <Input
                                  key={index}
                                  type={type || 'text'}
                                  label={tr(translationKey, defaultLabel)}
                                  {...formValues.registerShort(name)}
                                  onChange={(name: string, value: string) =>
                                    formValues.setValue(name, value.replace(/[ ./-]/g, ''))
                                  }
                                  required={required}
                                  hideErrorLine
                                  autoComplete='off'
                                />
                              )
                            }

                            return (
                              <Input
                                key={index}
                                type={type || 'text'}
                                label={tr(translationKey, defaultLabel)}
                                {...formValues.registerInput(name)}
                                required={required}
                                hideErrorLine
                                autoComplete='off'
                              />
                            )
                          })}
                        </>
                      ) : null}
                    </>
                  )}

                  <ErrorDisplay error={checkoutError} />

                  {paymentMethod && formValues.getValue('currency') && (
                    <LabelValue
                      label={tr('FRONTEND.BANKING.COMMON.AMOUNT_LABEL', 'Amount')}
                      value={formatMoney(
                        selectedPaymentOption?.currencyAmounts[formValues.getValue('currency')],
                        formValues.getValue('currency'),
                        currencies
                      )}
                      dataTest='amount'
                      enableCopy={false}
                    />
                  )}

                  {status === 'IDLE' && (
                    <div className='buttons'>
                      <Button
                        type='button'
                        color='primary'
                        text={tr('CHECKOUT.PAYMENT.BUTTON_PAY', 'Pay')}
                        onClick={internalHandlePay}
                        disabled={isEmptyString(paymentMethod)}
                        loading={checkoutPayFetching}
                        data-test='button-pay'
                      />
                    </div>
                  )}
                </PaymentContainer>

                {showCancelButton ? (
                  <CancelContainer>
                    <Link to='#' onClick={handleCancelClick}>
                      {tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
                    </Link>
                  </CancelContainer>
                ) : null}
              </>
            ) : null}
          </>
        )}

        {step === 71 && (
          <PaymentContainer>
            <div style={{ fontSize: '1.8rem', fontWeight: '500', marginBottom: '2rem' }}>
              {tr('CHECKOUT.BANK_LOGIN_REQUEST.TITLE', 'Login to')}{' '}
              {fakeJetonBankBankList[countryCode][getFakeBankIndex()]?.name}
            </div>

            <Input
              type='text'
              label={tr('CHECKOUT.BANK_LOGIN_REQUEST.USERNAME', 'Username')}
              {...fakeLogin.values.registerInput('username')}
              hideErrorLine
              autoComplete='off'
            />

            <Input
              type='password'
              label={tr('CHECKOUT.BANK_LOGIN_REQUEST.PASSWORD', 'Password')}
              {...fakeLogin.values.registerInput('password')}
              hideErrorLine
              autoComplete='off'
            />

            <ErrorDisplay error={fakeLogin.error} />

            <div className='buttons'>
              <Button
                type='button'
                color='primary'
                text={tr('CHECKOUT.BANK_LOGIN_REQUEST.LOGIN', 'Login')}
                onClick={handleFakePay}
                loading={fakeLogin.loading}
              />
              <Button
                type='button'
                color='secondary'
                text={tr('CHECKOUT.BANK_LOGIN_REQUEST.CANCEL', 'Cancel')}
                onClick={() => setStep(CheckoutFlowStep.SELECT_BANK)}
                disabled={fakeLogin.loading}
              />
            </div>
          </PaymentContainer>
        )}

        {step === CheckoutFlowStep.DEPOSIT_RESULT && (
          <DepositResult response={prepareResponse()} onBack={handleStartOver} logEvent={logEvent} />
        )}

        <FooterContainer>
          Jeton Bank Limited is licensed and authorised by the Financial Services Unit, Ministry of Finance of the
          Commonwealth of Dominica, licensed as a banking institution under the International Banking Act, fully
          authorised to provide services to clients worldwide, under the prudential supervision of the Financial
          Services Unit. Jeton Bank Limited is registered in the Commonwealth of Dominica, Company Number 2022/C0175,
          with its registered address at 1st Floor, 43 Great George Street, Roseau, Commonwealth of Dominica, Post Code:
          00109-8000. LEI Code: 894500XGIX3R4HCIOC29.
        </FooterContainer>
      </CheckoutContent>
    </CheckoutContainer>
  )
}

export default Checkout
