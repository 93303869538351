import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { isValidArray, tr } from 'mmfintech-commons'

import { ThreeDots } from 'react-loader-spinner'
import { MethodPane } from './MethodPane'
import { MethodButton } from './MethodButton'
import { SelectMethodContainer, SelectMethodEmpty, SelectMethodStyled, SelectMethodTitle } from './SelectMethod.styled'

import { PaymentMethodEnum } from 'mmfintech-commons-types'
import { isJetonWallet } from 'mmfintech-checkout-commons'

type SelectMethodProps = {
  className?: string
  method: string
  setMethod: (method: string) => void
  specialCase: boolean
  errorVisible: boolean
}

export const SelectMethod = (props: SelectMethodProps) => {
  const { className, method, setMethod, specialCase, errorVisible } = props

  // const [options, setOptions] = useState(null)

  const { checkoutOptions, checkoutOptionsFetching } = useSelector(
    ({ checkout: { checkoutOptions, checkoutOptionsFetching } }) => ({
      checkoutOptions,
      checkoutOptionsFetching
    }),
    shallowEqual
  )

  useEffect(() => {
    if (Array.isArray(checkoutOptions) && checkoutOptions.length === 1) {
      setMethod(checkoutOptions[0].paymentMethod)
    }
    // eslint-disable-next-line
  }, [checkoutOptions])

  if (checkoutOptionsFetching) {
    return (
      <SelectMethodStyled>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ThreeDots height='1rem' color='silver' />
        </div>
      </SelectMethodStyled>
    )
  }

  if (isValidArray(checkoutOptions)) {
    if (checkoutOptions.length > 1) {
      return (
        <SelectMethodStyled>
          <SelectMethodTitle>Payment With</SelectMethodTitle>
          <SelectMethodContainer className={className}>
            {checkoutOptions.map(option => (
              <MethodButton
                key={option.paymentMethod}
                name={option.paymentMethod}
                selected={option.paymentMethod === method}
                setSelected={setMethod}
              />
            ))}
          </SelectMethodContainer>
        </SelectMethodStyled>
      )
    }

    return (
      <SelectMethodStyled>
        <SelectMethodTitle>Paying With</SelectMethodTitle>
        <MethodPane method={specialCase && isJetonWallet(method) ? PaymentMethodEnum.INSTANT_BANK_TRANSFER : method} />
      </SelectMethodStyled>
    )
  }

  return errorVisible ? (
    <SelectMethodStyled>
      <SelectMethodEmpty>{tr('CHECKOUT.PAYMENT.NO_METHODS', 'No payment methods available')}</SelectMethodEmpty>
    </SelectMethodStyled>
  ) : null
}
